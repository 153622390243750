import { reactive, toRefs } from 'vue';
import { useSdk } from './use-mycure';
import { useFacilities } from './use-facilities';
// import { useServices } from './use-services';
import { formatRecord } from '@/utils/formatters';
import { format } from 'date-fns';

const SERVICE_NAME = 'diagnostic-measures';
// const LAB_TYPE = 'laboratory';
// const IMAGING_TYPE = 'radiology';
const state = reactive({
  testMeasures: [],
  // laboratoryTests: [],
});

// const populateService = () => {
//   const query = {
//     method: 'findOne',
//     service: 'diagnostic-',
//     foreignKey: 'ref',
//     localKey: 'id',
//   };
//   return query;
// };

// const populateArchivedService = () => {
//   const query = {
//     method: 'findOne',
//     service: 'services/services-archived',
//     foreignKey: 'ref',
//     localKey: 'id',
//   };
//   return query;
// };

function mapMeasures (test) {
  return {
    ...test,
    formattedCreatedAt: test.createdAt ? format(new Date(test.createdAt), 'MMM dd, yyyy') : null,
  };
}

// function mapArchivedTests (test) {
//   return {
//     ...test,
//     formattedCreatedAt: test.createdAt ? format(new Date(test.createdAt), 'MMM dd, yyyy') : null,
//     formattedArchivedAt: test.archiedAt ? format(new Date(test.archiedAt), 'MMM dd, yyyy') : null,
//   };
// }

export function useDiagnosticTestMeasures () {
  const sdk = useSdk();
  const { getActiveFacility } = useFacilities();
  // const { listArchivedItems } = useServices();

  // good for forms
  async function listTestMeasures (test) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const testId = test?.id;

    const query = {
      facility: facilityId,
      $sort: {
        createdAt: -1,
      },
      test: testId,
    };
    const result = await sdk?.list(SERVICE_NAME, query);
    const testMeasures = result.data.map(mapMeasures);
    state.testMeasures = testMeasures;
  }

  async function get (test) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const testId = test?.id;

    const query = {
      facility: facilityId,
      $sort: {
        createdAt: -1,
      },
      test: testId,
    };
    const res = sdk?.get(SERVICE_NAME, query);
    return res;
  }

  async function getList (test) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const testId = test?.id;

    const query = {
      facility: facilityId,
      $sort: {
        createdAt: -1,
      },
      test: testId,
    };
    const res = await sdk?.list(SERVICE_NAME, query);
    return res.data;
  }

  async function create (data, testId) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    if (!testId) return;
    const payload = {
      facility: facilityId,
      test: testId,
      ...data,
    };
    await sdk?.create(SERVICE_NAME, payload);
  }

  async function update (id, data) {
    const result = await sdk?.update(SERVICE_NAME, id, data);
    const index = state.testMeasures.findIndex((item) => item.id === id);
    const test = formatRecord(result);
    state.testMeasures[index] = test;
    return test;
  }

  async function remove (id) {
    await sdk?.delete(SERVICE_NAME, id);
    const index = state.testMeasures.findIndex((item) => item.id === id);
    state.testMeasures.splice(index, 1);
  }

  return {
    ...toRefs(state),
    listTestMeasures,
    get,
    getList,
    create,
    update,
    remove,
  };
}
